
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mapKey } from '@/utils/config'
import AMapLoader from '@amap/amap-jsapi-loader'
import { LocationList } from '@/types/ordinarySeedling'
import { drawPolygon } from '@/utils/formatData'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  name: 'MapLocation',
  components: { UploadFile, 'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer' as any) },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    },
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class MapLocation extends Vue {
  // 是否是古树
  @Prop({ default: false }) private ancientTrees?: boolean
  // 是否有数量输入框
  @Prop({ default: false }) private numberInput?: boolean
  // 地图点是否可以改变(用于详情页面)
  @Prop({ default: true }) private isChange?: boolean
  // 项目id(获取电子围栏)
  @Prop() private projectId?: [number, number]
  // 区域id(获取电子围栏)
  @Prop() private projectAreaId?: any
  // 回显时点的数据
  @Prop() private locationList?: LocationList[]
  // 图标
  @Prop() private icon!: string
  // 打点资源名称(详情必传)
  @Prop({ default: '' }) private name?: string
  // 打点资源单位
  @Prop({ default: '' }) private unit?: string
  // 地图模式
  @Prop({ default: '' }) private mapModel?: false

  private map: AMap.Map | any = null
  private marker: AMap.Marker | any = null
  private infoWindow: AMap.InfoWindow | any = null
  private fenceCode: number | null = null
  private treeWidth: number | null = null
  private growthTrend: string | null = ''
  private fileList: any = []

  private imgViewerVisible = false
  private imgList = [] as any
  private fenceCodeShow = false
  private ploygons: Array<AMap.Polygon> = []
  private showDialog = false
  private locInfo: { lat: string; lng: string } = {
    lng: '',
    lat: ''
  }

  private locRules = {
    lng: [{ required: true, message: '请输入经度' }],
    lat: [{ required: true, message: '请输入纬度' }]
  }

  private currentIndex = 0

  @Watch('locationList')
  locationChange () {
    console.log('编辑回显')
    this.map && this.markerListAdd()
  }

  @Watch('projectId')
  projectChange () { // 设置地图中心随项目移动
    this.getProjectLocation()
  }

  @Watch('projectAreaId')
  projectAreaIdsChange (val: any) {
    if (val) {
      this.getAreaDetail()
    }
  }

  created () {
    this.loadMap()
  }

  // 获取项目电子围栏
  getProjectLocation () {
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', () => {
          // 清除ploygons事件
        })
      })
    }
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.projectId
    }).then((res) => {
      // console.log('res', res)
      this.ploygons = drawPolygon(this.map, res.locationList, null, (e: any) => {
        this.isChange && this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
      })
    })
  }

  // 区域详情
  getAreaDetail () {
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', () => {
          // 清除ploygons事件
        })
      })
    }
    this.$axios.get(this.$apis.project.selectYhProjectAreaByProjectAreaId, {
      projectAreaId: this.projectAreaId
    }).then((res) => {
      if (this.map) {
        this.map.remove(this.ploygons)
        this.ploygons = drawPolygon(this.map, res.locationList, null, (e: any) => {
          this.isChange && this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
        })
      }
    })
  }

  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      this.map = new AMap.Map('map', {
        zoom: 11,
        doubleClickZoom: false
      })
      if (this.projectAreaId) {
        this.getAreaDetail() // 获取区域电子围栏
      } else if (this.projectId) {
        this.getProjectLocation() // 获取项目电子围栏
      }
      this.markerListAdd() // 通过 this.locationList 回显选中的点
      // this.isChange 为假就是详情，不绑定点击事件
      this.isChange && this.map.on('click', (e: any) => {
        this.markerClick([e.lnglat.getLng(), e.lnglat.getLat()])
      })
    })
  }

  destroyed () {
    if (this.map.getAllOverlays('marker').length > 0) {
      this.map.getAllOverlays('marker').map((item: AMap.Marker) => {
        item.off('click', () => {
          // 清除marker事件
        })
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', () => {
          // 清除ploygons事件
        })
      })
    }
    if (this.map) {
      this.map.off('click', null)
      this.map.destroy()
    }
  }

  handleClose (done: Function) {
    this.resetForm('locInfo')
    done()
  }

  onCancel () {
    this.resetForm('locInfo')
    this.showDialog = false
  }

  // 手动添加点
  onAdd (formName: string) {
    (this.$refs[formName] as ElForm).validate((valid) => {
      if (valid) {
        this.markerClick([Number(this.locInfo.lng), Number(this.locInfo.lat)])
        this.onCancel()
      } else {
        return false
      }
    })
  }

  resetForm (formName: string) {
    (this.$refs[formName] as ElForm).resetFields()
  }

  // 添加marker
  markerClick (position: [number, number]) {
    // console.log('触发地图点击事件', this.marker)
    // 古树的添加和编辑走这里的代码
    if (!this.numberInput && this.marker) {
      this.marker.setMap(null)
      this.marker = null
    }
    // 往地图上添加一个 marker 点
    this.markerAdd([Number(position[0]), Number(position[1])], { fenceCode: 1 })
    // 古树的添加和编辑不走这里的代码，没有数量,默认为1
    if (this.numberInput) {
      // 添加数量文本窗
      this.addSetLabel({
        fenceCode: 1,
        treeWidth: '',
        growthTrend: '0',
        fileList: []
      })

      // 打开弹窗
      this.openInfoWindow(this.marker)
      // 绑定 marker 点击事件，控制弹窗显示隐藏
      this.marker.on('click', (MapsEvent: any) => {
        if (this.infoWindow) {
          this.infoWindow.close() // 关闭信息窗
          this.infoWindow = null
        } else {
          this.openInfoWindow(MapsEvent.target)
        }
      })
    }
    this.markerChange()
  }

  // 往地图上添加一个点且添加参数
  markerAdd (position: [number, number], item: any) {
    this.marker = new AMap.Marker({
      icon: new AMap.Icon({
        // 图标的取图地址
        image: this.icon
      }),
      offset: new AMap.Pixel(-20, -40),
      extData: {
        data: {
          fenceCode: item.fenceCode,
          longitude: Number(position[0]),
          latitude: Number(position[1]),
          treeWidth: item.treeWidth || '',
          growthTrend: item.growthTrend || '0',
          fileList: item.fileList || []
        }
      },
      position: [Number(position[0]), Number(position[1])]
    })
    this.map.add(this.marker) // 将一个图层添加到地图上
  }

  // 回显
  markerListAdd () {
    this.locationList && this.locationList.map((item: LocationList) => {
      // 根据 经纬度 和 fenceCode 添加点
      this.markerAdd([Number(item.longitude), Number(item.latitude)], item)
      if (this.numberInput || !this.isChange) {
        // console.log('古树的新增、编辑不会执行')
        this.addSetLabel(item)
      }
      // 详情点击marker只放大图片
      !this.isChange && this.marker.on('click', (MapsEvent: any) => {
        this.showImgViewer(MapsEvent.target._originOpts.extData.data.fileList)
      })
      // 新增或编辑点击marker打开infoWindow及放大图片
      this.isChange && this.marker.on('click', (MapsEvent: any) => {
        if (this.infoWindow) {
          this.infoWindow.close() // 关闭信息窗
          this.infoWindow = null
        } else {
          this.numberInput && this.openInfoWindow(MapsEvent.target)
        }
      })
    })
  }

  // 打开弹窗
  openInfoWindow (target: any) {
    this.fenceCode = null
    this.treeWidth = null
    this.growthTrend = null
    this.fileList = null

    this.marker = target
    this.fenceCodeShow = true
    this.$nextTick(() => {
      this.infoWindow = new AMap.InfoWindow({
        isCustom: true, // 使用自定义窗体
        content: (this.$refs.input as any),
        offset: new AMap.Pixel(0, -45)
      })
      this.fenceCode = this.marker.getExtData().data.fenceCode
      this.treeWidth = this.marker.getExtData().data.treeWidth
      this.growthTrend = this.marker.getExtData().data.growthTrend
      this.fileList = this.marker.getExtData().data.fileList
      this.infoWindow.open(this.map, target.getPosition())
    })
  }

  showImgViewer (row: any) {
    if (!row.length) {
      return
    }
    this.imgList = []
    row.forEach((item: any) => {
      this.imgList.push(`${item.filePrefix}${item.fileUrl}`)
    })
    this.imgViewerVisible = true
    const m = (e: any) => { e.preventDefault() }
    document.body.style.overflow = 'hidden'
    document.addEventListener('touchmove', m, false) // 禁止页面滑动
  }

  closeImgViewer () {
    this.imgViewerVisible = false
    const m = (e: any) => { e.preventDefault() }
    document.body.style.overflow = 'auto'
    document.removeEventListener('touchmove', m, true)
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    (this as any).fileList.push(file)
  }

  imgRemove (index: number) {
    (this as any).fileList.splice(index, 1)
  }

  // 保存
  save () {
    // const value = this.treeWidth as any
    // const reg = /^([1-9][0-9]*|[0-9])(\.\d{0,2})?$/
    // if (value && (!reg.test(value) || +value === 0)) {
    //   return this.$message.warning('胸径为正整数且最多保留两位小数')
    // }

    // const btn1 = document.getElementById('btn2') as any
    // btn1.addEventListener('click', () => {
    //   alert('按钮1被点击了')
    // })

    if (this.fenceCode) {
      this.marker.getExtData().data.fenceCode = this.fenceCode
      this.marker.getExtData().data.treeWidth = this.treeWidth
      this.marker.getExtData().data.growthTrend = this.growthTrend
      this.marker.getExtData().data.fileList = this.fileList
      this.addSetLabel({
        fenceCode: this.fenceCode,
        treeWidth: this.treeWidth,
        growthTrend: this.growthTrend,
        fileList: this.fileList
      })

      this.infoWindow.close() // 关闭信息窗
      this.infoWindow = null
      this.markerChange()
    } else {
      this.$message.warning('请输入1~9999的整数')
    }
  }

  // 删除点
  deleteMarker () {
    this.marker.remove()
    this.infoWindow.close() // 关闭信息窗
    this.infoWindow = null
    this.markerChange()
  }

  // 添加数量文本窗
  addSetLabel (item: any) {
    const imgUrl = item.fileList[0]?.filePrefix + item.fileList[0]?.fileUrl
    const fileLength = item.fileList.length
    let html = ''
    if (!this.ancientTrees) {
      if (fileLength) {
        html = `<div class="infoWindow2" v-html>
          <div class="line">
            <p class="item">数量：<span>${item.fenceCode}</span></p></n>
            <p class="item">胸径：<span>${item.treeWidth || '--'}</span></p>
          </div>
          <p>生产态势：<span>${item.growthTrend === '0' ? '良好' : item.growthTrend === '1' ? '一般' : '不佳'}</span></p>
          <div class="img">
            <span>现场图片：</span><img src="${imgUrl}" id="btn2" alt="" srcset="">
          </div>
        </div>`
      } else {
        html = `<div class="infoWindow2" v-html>
          <div class="line">
            <p class="item">数量：<span>${item.fenceCode}</span></p></n>
            <p class="item">胸径：<span>${item.treeWidth || '--'}</span></p>
          </div>
          <p>生产态势：<span>${item.growthTrend === '0' ? '良好' : item.growthTrend === '1' ? '一般' : '不佳'}</span></p>
          <p class="item">现场图片：<span>--</span></p>
        </div>`
      }
    } else {
      html = `<div>
      </div>`
    }
    this.marker.setLabel({
      content: html, // 设置文本标注内容
      offset: new AMap.Pixel(0, -10),
      direction: 'top' // 设置文本标注方位
    })
  }

  // 返回数据
  markerChange () {
    const locationList: LocationList[] = []
    this.map.getAllOverlays('marker').map((item: AMap.Marker) => {
      locationList.push(item.getExtData().data)
    })
    // console.log('locationList', locationList)
    this.$emit('locationChange', locationList)
  }

  fenceCodeChange (value: string) {
    if (value && Number(value) > 9999) {
      this.fenceCode = Number(value.substr(0, 4))
    } else if (value) {
      this.fenceCode = Number(value.replace(/[^0-9]/g, ''))
    }
  }

  fenceCodeChange2 (value: string) {
    if (value && Number(value) > 9999) {
      this.treeWidth = Number(value.substring(0, 4))
    } else if (value) {
      this.treeWidth = Number(value.replace(/[^0-9]/g, ''))
    }
    if (value.indexOf('.') !== -1) { // 如果输入框中包含多个小数点
      this.treeWidth = value.substring(0, value.indexOf('.') + 3) as any// 取小数点前两位和小数点
    }
  }

  changeLayer (value: number) {
    this.currentIndex = value
    this.map.setLayers([])
    if (value === 0) {
      // 添加标准地图图层
      this.map.setMapStyle('amap://styles/normal')
      this.map.add(new AMap.TileLayer())
    } else {
      // 添加卫星地图图层
      this.map.add(new AMap.TileLayer.Satellite())
    }
  }
}
